<template>
    <div id="backoffice" class="backoffice-wrapper">
        <div class="demo-logo" :style="{ backgroundImage: `url(${demoLogo})`}"></div>
        <div>{{time}}</div>
        <router-view @showAlert="showAlert($event.message, $event.type)"></router-view>
        <Alert ref="Alert" :alertContent="alertContent" :alertVariant="alertVariant"></Alert>
    </div>
</template>

<script>
    import "@/assets/css/custom-food.css"
    import Alert from "@/components/Common/Alert";
    import {isNotNull, getConfig, setAppContext} from "@/assets/js/Utils"

    export default {
        name: "Backoffice",
        components: {Alert},
        data(){
            return{
                alertVariant: "",
                alertContent: "",
                demoLogo: "",
                time: "",
                clockParams: {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit"
                }

            }
        },
        created(){  
            setAppContext(this);
            this.$i18n.locale = "en"
            let locale = this.$route.params.locale
            if(locale){
                this.$i18n.locale = locale
            }
            let storedDemoId = localStorage.getItem("DKC_demoId")
            if(isNotNull(storedDemoId) && storedDemoId != this.$route.params.demoId){
                sessionStorage.clear()
                window.location.reload()
            }
            this.demoLogo = getConfig().DKW_URL + getConfig().PATH_IMG + this.$route.params.demoId;
            localStorage.setItem("DKC_demoId", this.$route.params.demoId)
            this.time = (new Date()).toLocaleString(locale, this.clockParams).split("à").join("")
            setInterval(() => {this.time = (new Date()).toLocaleString(locale, this.clockParams).split("à").join("")}, 999);
        },
        methods: {
            showAlert(content, type = ""){
                var variant = "";
                switch (type) {
                    case 'success':
                        variant = "success";
                        break;
                    case 'error':
                        variant = "danger";
                        break;
                    case 'info':
                        variant = "info";
                        break;
                    default:
                        variant = "info";
                }
                this.alertContent = content;
                this.alertVariant = variant;
            }
        }
        
    }

</script>
