<template>
    <div id="alert" class="fixed-bottom">
        <b-alert :show="dismissCountDown"
                 dismissible
                 fade
                 @dismissed="dismissCountDown=0; $emit('dismissed', dismissCountDown)"
                 @dismiss-count-down="countDownChanged; $emit('dismissed', dismissCountDown)"
                 :variant="variant">
            {{content}}
        </b-alert>
    </div>
</template>

<script>
    export default {
        name: "Alert",
        props: {
            "alertContent": String,
            "alertVariant": String
        },
        data () {
            return {
                dismissSecs: 5,
                dismissCountDown: 0,
                variant: "info",
                content: "Content"
            }
        },
        created(){
            this.refresh();
        },
        methods: {
            countDownChanged (dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
            showAlert () {
                this.dismissCountDown = this.dismissSecs
            },
            refresh (){
                if(this.alertContent && this.alertContent != ""){
                    this.content = this.alertContent;
                    this.variant = this.alertVariant;
                    this.showAlert();
                }
            }
        },
        watch:{
            alertContent: function() {
                this.refresh();
            }
        }
    }
</script>

<style scoped>

</style>